<template>
  <div>
    <b-card-text>{{ $t(`hall.${data.slug}`) }}</b-card-text>
    <b-form-file
      accept=".jpg, .png"
      v-model="file"
      :placeholder="$t('any_file_select')"
      @input="openEditor(file, data.slug)"
    />
    <b-img
      :src="preview_image"
      class="preview-img-component"
      style="max-height: 300px; object-fit: contain; margin-bottom: 10px;"
    />
  </div>
</template>

<script>
import {
  BCol,
  BButton,
  BImg,
  BCard,
  BFormFile,
  BCardText,
} from "bootstrap-vue";

import { updatePersonalizations } from "@api/personalizations";

export default {
  props: ["data"],
  components: {
    BCard,
    BCol,
    BButton,
    BFormFile,
    BCardText,
    BImg,
  },
  data() {
    return {
      file: null,
      preview_image: this.data.image_url,
    };
  },
  methods: {
    openEditor(file, type) {
      if (file != null) {
        this.$emit("open_editor", file, type);
      }
    },
    saveImage() {
      const $this = this;
      let formData = new FormData();
      if ($this.file) {
        formData.append("image", $this.file);
      }
      updatePersonalizations($this.data.id, formData)
        .then((data) => {})
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
